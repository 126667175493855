import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Heading from '../components/Heading'
import Sidebar from '../components/Sidebar'
import SidebarContent from '../components/Sidebar-Content'
import Image from '../components/Image'

import pic05 from '../assets/images/Logo_Original.png'

const structuredData = JSON.stringify({
  "@context": "https://schema.org",
  "@type": "SportsActivityLocation",
  "@id": "https://www.plates-pilates.com",
  "name": "Plates",
  "image": "https://www.plates-pilates.com/static/photo1.jpg",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "2008 S Norwood St",
    "addressLocality": "Philadelphia",
    "addressRegion": "PA",
    "postalCode": "19145",
    "addressCountry": "US"
  },
  "geo": {
    "@type": "GeoCoordinates",
    "latitude": 39.926910,
    "longitude": -75.181680
  },
  "url": "https://www.plates-pilates.com",
  "logo": "https://www.plates-pilates.com/icons/icon-144x144.png",
  "telephone": "+12672173874",
  "email": "info@plates-pilates.com",
  "openingHoursSpecification": [
    {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday"
      ],
      "opens": "08:00",
      "closes": "19:00"
    },
    {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Saturday",
        "Sunday"
      ],
      "opens": "08:00",
      "closes": "12:00"
    }
  ]
})
console.log(structuredData)

const About = props => (
  <Layout>
    <Helmet>
      <title>Plates - About</title>
      <meta name="description" content="Learn More about us. Our Contact Info and Our Story" />
      <link rel="canonical" href="https://www.plates-pilates.com/about" />
      <script type="application/ld+json">{structuredData}</script>
    </Helmet>
    <div id="main" className="wrapper style1">
      <div className="container">
        <Heading
          class="major"
          title="About Plates"
        />
        <div className="row gtr-150">
          <Sidebar>
          <section>
            {/* Begin Mailchimp Signup Form */}
            <div id="mc_embed_signup">
              <h3>Subscribe to Newsletter</h3>
              <form action="https://plates-pilates.us19.list-manage.com/subscribe/post?u=598469ecc42be8241b7474d9a&amp;id=4dfb45fa31"
                    method="post"
                    id="mc-embedded-subscribe-form"
                    name="mc-embedded-subscribe-form"
                    className="validate"
                    target="_blank"
                    noValidate>
                <div id="mc_embed_signup_scroll" className="row gtr-uniform gtr-50">
                  <div className="indicates-required">
                    <span className="asterisk">*</span> indicates required
                  </div>
                  <div className="mc-field-group col-12">
                    {/* <label for="mce-EMAIL">Email Address  <span class="asterisk">*</span></label> */}
                    <input
                      type="email"
                      name="EMAIL"
                      className="required email"
                      id="mce-EMAIL"
                      aria-label="Email"
                      placeholder="Email *"
                    />
                  </div>
                  <div className="mc-field-group col-6 col-12-xsmall">
                    {/* <label for="mce-FNAME">First Name </label> */}
                    <input
                      type="text"
                      name="FNAME"
                      className=""
                      id="mce-FNAME"
                      aria-label="First Name"
                      placeholder="First Name"
                    />
                  </div>
                  <div className="mc-field-group col-6 col-12-xsmall">
                    {/* <label for="mce-LNAME">Last Name </label> */}
                    <input
                      type="text"
                      name="LNAME"
                      className=""
                      id="mce-LNAME"
                      aria-label="Last Name"
                      placeholder="Last Name"
                    />
                  </div>
                  <div className="mc-field-group size1of2 col-12">
                    <p>Birthday:</p>
                    <div className="datefield row">
                      <span className="subfield monthfield">
                        <input
                          className="birthday"
                          type="text"
                          pattern="[0-9]*"
                          aria-label="Month"
                          placeholder="MM"
                          size="2"
                          maxLength="2"
                          name="BIRTHDAY[month]"
                          id="mce-BIRTHDAY-month"
                        />
                      </span>
                      <span className="subfield dayfield">
                        <input
                          className="birthday "
                          type="text"
                          pattern="[0-9]*"
                          aria-label="Day"
                          placeholder="DD"
                          size="2"
                          maxLength="2"
                          name="BIRTHDAY[day]"
                          id="mce-BIRTHDAY-day"
                        />
                      </span>
                    </div>
                  </div>
                  <div id="mce-responses" className="clear">
                    <div className="response" id="mce-error-response" style={{display: "none"}}></div>
                    <div className="response" id="mce-success-response" style={{display: "none"}}></div>
                  </div>
                  {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                  <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true">
                    <input type="text" name="b_598469ecc42be8241b7474d9a_4dfb45fa31" tabIndex="-1" value="" onChange={function() {return null}} />
                  </div>
                  {/* Buttons */}
                  <div className="col-12">
                    <ul className="actions">
                      <li>
                        <input
                          type="submit"
                          value="Subscribe"
                          name="subscribe"
                          id="mc-embedded-subscribe"
                          className="button primary"
                          alt="Subscribe"
                        />
                      </li>
                      <li>
                        <input type="reset" value="Reset" alt="Reset" />
                      </li>
                    </ul>
                  </div>
                </div>
              </form>
            </div>
            {/* End mc_embed_signup */}
          </section>
          <hr />
          <section>
            <h3>Contact Info</h3>
            <address>
              <ul className="alt">
                <li>
                  Email: <br />
                  <a href="mailto:info@plates-pilates.com">info@plates-pilates.com </a>
                </li>
                <li>
                  Phone: <br />
                  <a href="tel:+12672173874">267-217-3874</a>
                </li>
                <li>
                  Address: <br />
                  Plates <br />
                  2008 S Norwood St<br />
                  Philadelphia, PA 19145
                </li>
              </ul>
            </address>
          </section>
          </Sidebar>

          <SidebarContent>
            <Image src={pic05} alt="Plates Pilates Logo" fit />
            <h3>Our Story</h3>
            <p>
              Hi, My name is Jessica. I am the virtual Pilates instructor at
              Plates Pilates. This idea came to me and my partner Mark during
              the first week of Philadelphia’s “Stay at Home” Order. The order
              prompted me to begin teaching Pilates mat classes virtually.
              I was incredibly grateful to remain connected with my clients,
              however I noticed that the experience was lacking a little something
              the studio environment provides.
            </p>
            <p>
              I have designed Plates to address the flaws I noticed. First, by ensuring
              that all students have the same props, our mat classes offer more
              fluidity and diversity in exercises that mimic the Pilates Chair,
              Tower, and Reformer we would use in the studio. Secondly, by offering
              a budget friendly option to have a reformer in your home.
            </p>
            <h5>Experience</h5>
            <p>
              <i>Jessica Kroboth completed her 450 hour Comprehensive Pilates
              Training with Drexel Pilates in 2015 and has taught at Drexel
              Pilates ever since. She is a Senior Instructor, studio manager,
              and supports the operations the Drexel Pilates Teacher Training
              Program.
              </i>
            </p>
            <p>
              <i>Jessica has taught Pilates and other group fitness
              classes in the Philadelphia region for over four years. You may
              have taken one of her classes at Ploome Pilates, Sculpt360,
              Thrive, Drexel Pilates, and… maybe virtually at Plates
              </i>
            </p>
          </SidebarContent>

        </div>
      </div>
    </div>
  </Layout>
)

export default About
